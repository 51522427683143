// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()

console.log('Hello World from Webpacker')
import 'stylesheets/application'

// Admin LTE # https://blog.isystk.com/system_develop/frontend/adminlte/569/
import 'admin-lte/plugins/fontawesome-free/css/all.min.css';
import 'admin-lte/dist/css/adminlte.min.css';
import jQuery from 'admin-lte/plugins/jquery/jquery.min.js';
window.$ = jQuery;
window.jQuery = jQuery;
import "admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js";
import 'admin-lte/plugins/daterangepicker/daterangepicker.js';
import 'admin-lte/plugins/daterangepicker/daterangepicker.css';
require('admin-lte/dist/js/adminlte.min.js');

import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';

const images = require.context('../images', true)

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
$(document).ready( () => {

});

document.addEventListener('DOMContentLoaded', () => {
    console.log('document.ready')
    const notyf = new Notyf({
        duration: 5000,
        position: {
            x: 'right',
            y: 'top'
        }
    })

    /** display flash notice **/
    let $flashNotice = $('#flash-notice')

    if($flashNotice.text().length != 0){
        notyf.success($flashNotice.text())
        $flashNotice.text('');
    }

    /** display flash alert **/
    let $flashAlert = $('#flash-alert')
    if($flashAlert.text().length != 0){
        notyf.error($flashAlert.text())
        $flashAlert.text('');
    }

    // TODO JSじゃなくてRailsだけでどうにかならないかは引き続き要検討
    const errors = document.querySelectorAll('span.invalid-feedback');
    errors.forEach( (el) => {
        el.parentElement.querySelector('input').classList.add('is-invalid')
    })
    const $inputDaterange = $('input.daterange')
    if ( $inputDaterange ) {
        const dateFormat = 'YYYY/MM/DD'
        const months = Array.from({length: 12}, (v, k) => `${k + 1}月`)
        const hiddens = $inputDaterange.nextAll('input[type=hidden]')
        const from = hiddens[0], to = hiddens[1]
        // const startDate = from.value == "" ? moment() : moment(from.value, dateFormat),
        //     endDate = to.value == "" ? moment().add(30, 'day') : moment(to.value, dateFormat)
        // console.log(`startDate=${startDate}, endDate=${endDate}`)

        $inputDaterange.daterangepicker({
            // startDate: startDate,
            // endDate: endDate,
            autoApply: true,
            // autoUpdateInput: false,
            locale: {
                format: dateFormat,
                applyLabel: "適用",
                cancelLabel: "キャンセル",
                fromLabel: "回帰",
                toLabel: "終了",
                daysOfWeek: [
                    "日",
                    "月",
                    "火",
                    "水",
                    "木",
                    "金",
                    "土"
                ],
                monthNames: months,
                "firstDay": 1,
                // autoUpdateInput: false,

            },
        }, (start, end, label) => {
            console.log(`start=${start}, end=${end}, label=${label}`)
            from.value = start.format(dateFormat)
            to.value = end.format(dateFormat)
        });
        // from.value = startDate.format(dateFormat)
        // to.value = endDate.format(dateFormat)
        // $inputDaterange.on('apply.daterangepicker', (ev, picker) => {
        //     console.log('hoge')
        //     $(this).val(picker.startDate.format(dateFormat) + ' - ' + picker.endDate.format(dateFormat));
        // })
        // if ( from.value != "" && to.value != "") {
        //     $inputDaterange.data('daterangepicker').setStartDate(moment(from.value, dateFormat))
        //     $inputDaterange.data('daterangepicker').setEndDate(moment(to.value, dateFormat))
        // }
    }

}, 'DOMContentLoaded')
